<script lang="ts" setup>
import {useSettingsStore} from "~/stores/settings";
import {useElementor, useWp} from "~/composables/Redmonkey";
import TitleInGrid from "~/components/Redmonkey/Base/TitleInGrid.vue";
import DecorInGrid from "~/components/Redmonkey/Base/DecorInGrid.vue";

const settingsStore = useSettingsStore();

const {
  getSettingsValue: getGeneralSettingValue,
  getMediaUrlFromMedia,
  getMediaAltFromMedia,
  getMediaWidthFromMedia,
  getMediaHeightFromMedia
} = useWp()

const {
  getSettingsValue,
  isThatDefault
} = useElementor();

const props = defineProps({
  element: Object
})

const getTitle = () => {
  return getSettingsValue(props.element, 'title') || 'Чому ми?'
}

const getItems = () => {
  let items = [];

  if (!isThatDefault(props?.element, 'use_default_items')) {
    items = getSettingsValue(props.element, 'why_we_items');
  } else {
    items = getGeneralSettingValue(settingsStore?.redmonkeySettings, 'whyWeItems', 'whyWe');
  }


  return items;
}

const getDecorTitle = () => {
  return getSettingsValue(props.element, 'title_end') || 'Приходи переконайся';
}

const items = [
  {
    component: TitleInGrid,
    title: getTitle()
  },
  ...getItems(),
  {
    component: DecorInGrid,
    title: getDecorTitle()
  }
]

const getImage = (item: { icon: any }) => {
  if (!isThatDefault(props?.element, 'use_default_items')) {
    return {
      url: item?.icon?.url,
      alt: item?.icon?.alt || getTitle(),
      width: 'auto',
      height: 'auto'
    }
  }

  return {
    url: getMediaUrlFromMedia(item.icon.node),
    alt: getMediaAltFromMedia(item.icon.node),
    width: getMediaWidthFromMedia(item.icon.node),
    height: getMediaHeightFromMedia(item.icon.node)
  }
}
</script>

<template>
  <div class="why-we">
    <div class="why-we-items flex wrap hide-mobile">
      <template v-for="item in items" :key="item.title">
        <component v-if="item.component" :is="item.component" :title="item.title"/>
        <div class="why-we-item" v-else>
          <picture v-if="getImage(item).url">
            <img :src="getImage(item).url" :alt="getImage(item).alt" :width="getImage(item).width" :height="getImage(item).height"/>
          </picture>
          <h3 v-if="item.title">{{item.title}}</h3>
          <div v-if="item.description" class="content" v-html="item.description"></div>
        </div>
      </template>
    </div>
    <div class="why-we-items why-we-items-mobile hide-desktop hide-ipad-p hide-medium">
      <TitleInGrid :title="getTitle()"/>
      <Swiper :slides-per-view="1.3" :loop="true" :space-between="15">
        <SwiperSlide v-for="item in getItems()" :key="item.title">
          <div class="why-we-item">
            <picture v-if="getImage(item).url">
              <img :src="getImage(item).url" :alt="getImage(item).alt" :width="getImage(item).width" :height="getImage(item).height"/>
            </picture>
            <h3 v-if="item.title">{{item.title}}</h3>
            <div v-if="item.description" class="content" v-html="item.description"></div>
          </div>
        </SwiperSlide>
      </Swiper>
      <DecorInGrid :title="getDecorTitle()"/>
    </div>
  </div>
</template>

<style lang="scss">
  @import "assets/css/source/components/widgets/why_we";
</style>